var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-box",
    { attrs: { bcolor: "#FFF" } },
    [
      _c(
        "c-box",
        {
          staticStyle: { "border-bottom": "1px solid #E8E8E8" },
          attrs: { padding: "10px 20px" }
        },
        [_vm._v("数据总览")]
      ),
      _c(
        "div",
        { staticClass: "top" },
        _vm._l(_vm.options, function(item, idx) {
          return _c("div", { key: idx, staticClass: "item" }, [
            _c("div", { staticClass: "fz-24 fz-w" }, [
              _vm._v(_vm._s(_vm.info[item.prop] || "0"))
            ]),
            _c("div", { staticClass: "fz-16" }, [_vm._v(_vm._s(item.label))])
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }